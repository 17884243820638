import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageHeader from '../components/PageHeader'
import NextSteps from '../components/NextSteps'
import PanelList from '../components/PanelList'

const SolutionsPage = ({ data: { prismicSolutionsPage } }) => {
  const { data } = prismicSolutionsPage
  return (
    <Layout>
      <Helmet
        title={`Charthouse | ${data.title.text}`}
        meta={[
          { name: 'description', content: data.introduction.text},
          { property: 'og:title', content: `Charthouse | ${data.title.text}` },
          { property: 'og:description', content: data.introduction.text },
          { name: 'twitter:title', content: `Chartouse | ${data.title.text}` },
          { name: 'twitter:description', content: data.introduction.text },
        ]}
      />
      <PageHeader 
        heading={data.title.text}
        introduction={data.introduction.html}
      />

      <Container>
        <PanelList list={data.solutions} />
      </Container>

      {data.next_steps && data.next_steps.length > 0 && 
        <NextSteps
          steps={data.next_steps}
        />
      }
    </Layout>
  )
}

export default SolutionsPage

export const pageQuery = graphql`
  query SolutionsPageQuery {
    prismicSolutionsPage {
      data {
        title {
          text
        }
        introduction {
          html
        }
        solutions {
          heading {
            text
          }
          image {
            url
          }
          description {
            html
          }
          link_label {
            text
          }
          link {
            url
          }
        }
        next_steps {
          heading {
            text
          }
          button_label {
            text
          }
          link {
            url
          }
        }
      }
    }
  }
`