import React from 'react'
import styled from 'styled-components'
import Panel from './Panel'
import Button from './Button'
import device from '../device'

const PanelList = ({ className, list }) => (
  <div className={className}>
    {list.map(({ heading, description, image, link, link_label}) => (
      <Panel key={heading.text}>
        <a href={link.url}>
          <img src={image.url} alt={heading.text} />
        </a>
        <div className="content">
          <h3>{heading.text}</h3>
          <div dangerouslySetInnerHTML={{ __html: description.html }} />
          <Button 
            linkTo={link.url}
            label={link_label.text}
          />
        </div>
      </Panel>
    ))}
  </div>
)

export default styled(PanelList)`
  ${Panel} {
    @media ${device.tablet} {
      display: flex;
    }
    
    img {
      height: 100%;
      max-height: 200px;
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    
      @media ${device.tablet} {
        width: 350px;
        max-height: 100%;
      }
    } 
    
    .content {
      margin-top: 40px;
      padding: 0px 40px 60px;
    
      @media ${device.tablet} {
        margin-top: 0;
        padding: 40px 60px 60px;
      }
    
      p {
        font-size: 18px;
        max-width: 500px;
      }
    }
  }
`



